import { createRouter, createWebHistory } from 'vue-router'
import { Store } from 'vuex'
import { State } from '@/store'
import { afterEachHook as modalStackAfterEachHook } from '@/common/components/modal/ModalStack.api'
import { beforeEachHook as userBeforeEachHook } from '@/modules/user/services/User.api'
import { routes } from '@/routes'
import { I18n } from '@/bootstrap/i18n'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL?.replace('/static', '')),
  routes: [],
})

// onlyOnePermissionRequired can be used in a route definition's meta.permissions array to indicate that
// the user needs to have at least one of the permissions in the array.
// By default, a user needs all permissions in the array.
export const onlyOnePermissionRequired = Symbol('anyOf')

export function setupRouter (store: Store<State>, i18n: I18n) {
  routes(store).forEach(r => router.addRoute(r))

  // Register the modal stack afterEach hook.
  if (store) {
    router.afterEach(modalStackAfterEachHook(store))
    router.afterEach((to) => {
      store.commit('app/setCurrentRoute', {
        name: to.name,
        params: to.params,
        meta: to.meta,
      })
    })
    if (i18n) {
      router.beforeEach(userBeforeEachHook(router, store, i18n))
    }
  }

  return router
}

export default router
