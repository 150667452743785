<script setup lang="ts">
  import { PropType } from 'vue'

  defineProps({
    tabs: {
      type: Array as PropType<ReadonlyArray<{ id: number|string, name: string }>>,
      required: true
    }
  })

  const active = defineModel<any>()
</script>

<template>
  <div>
    <div class="overflow-x-auto border-b border-gray-300 scrollbar-thin">
      <ul class="flex text-xs">
        <li
          v-for="tab in tabs"
          :key="tab.id"
          class="first:rounded-tl last:rounded-tr border-gray-300 border-l border-t last:border-r whitespace-nowrap"
        >
          <a
            href="#"
            class="block px-4 py-2.5 bg-white rounded-t whitespace-nowrap"
            :class="{ 'opacity-40 !bg-gray-100': tab.id !== active.id }"
            @click.prevent="active = tab"
          >
            {{ tab.name }}
          </a>
        </li>
      </ul>
    </div>

    <div class="bg-white border border-gray-300 border-t-0 p-4 rounded-b shadow">
      <slot :active="active" />
    </div>
  </div>
</template>