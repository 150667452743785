<script setup lang="ts">
  import { onBeforeUnmount, onMounted } from 'vue'

  const emit = defineEmits(['close'])
  const props = defineProps({
    title: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Boolean,
      default: true,
    },
    innerClasses: {
      type: String,
      default: 'p-6 sm:p-8',
    },
    loaderText: {
      type: String,
      default: ''
    },
    lockScrolling: {
      type: Boolean,
      default: true,
    },
    maxWidth: {
      type: String,
      default: '600px'
    },
  })

  // clickHandler emits the close event if the overlay was clicked.
  function clickHandler (e: MouseEvent) {
    if ((e.target as HTMLElement).classList.contains('popup-overlay')) {
      emit('close', e)
    }
  }

  onMounted(() => {
    if (props.lockScrolling) {
      document.body.classList.add('overflow-hidden')
    }
  })

  onBeforeUnmount(() => {
    document.body.classList.remove('overflow-hidden')
  })
</script>

<template>
  <div
    class="popup-overlay fixed inset-0 z-40 overflow-y-auto flex items-center justify-center"
    @click="clickHandler"
  >
    <div class="popup-body-host max-w-full mx-auto">
      <div
        class="popup-body bg-white rounded-md shadow-xl transition-all"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <transition name="fade" mode="out-in">
          <div v-if="!loading" key="content">
            <div :class="innerClasses">
              <h3 v-if="title" id="modal-headline" class="text-lg leading-6 text-gray-900 mb-3">
                {{ title }}
              </h3>
              <div class="text-sm leading-5 text-gray-600">
                <slot />
              </div>
            </div>
            <div v-if="$slots.actions && actions" class="bg-gray-50 px-4 py-3 sm:px-6 flex justify-end actions rounded-b-md">
              <slot name="actions" />
            </div>
          </div>
          <Loader v-else key="loader" class="py-24" :text="loaderText" />
        </transition>
      </div>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
  .popup-overlay
    background rgba(0, 0, 0, .8)

  .popup-body
    transform scale(1)
    transition .2s ease-out
    transition-property transform, opacity
    transition-delay 0

  .popup-body-host
    width 100%
    max-width v-bind(maxWidth)
    padding 0 20px

  .actions
    .button + .button
      @apply ml-4
</style>

<style lang="stylus">
  .popup-body
    .loading-spinner
      margin 5.15rem auto

    .button .loading-spinner
      margin 0 auto

  .actions
    .button + .button
      @apply ml-4

  .popup-enter-active,
  .popup-leave-active
    transition opacity .2s ease-out

  .popup-enter-from,
  .popup-leave-to
    opacity 0

    .popup-body
      opacity 0
      transform scale(.7)

  .popup-enter-active .popup-body
    transition-delay .2s

  .popup-leave-to
    transition-delay .4s

    .popup-body
      opacity 0
      transition-delay 0
      transform scale(.7)
</style>
