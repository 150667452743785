import storage from '@/bootstrap/storage'
import { createI18n, I18n as VueI18n } from 'vue-i18n'
import { Locale } from '@/common/graphql/types'
import { LocaleMessages } from '@intlify/core-base'

// Fallback locale is always loaded.
import de from '@/locales/de.json'

export type MessageSchema = typeof de

// All other locales are loaded dynamically.
const loadedLocales: Locale[] = [Locale.De]
export const availableLocales = [
  Locale.De,
  Locale.En,
  Locale.Fr,
  Locale.It
]

export type I18n = VueI18n<LocaleMessages<MessageSchema, Locale>, LocaleMessages<any, Locale>, LocaleMessages<any, Locale>>

let i18n: I18n

export async function setupI18N () {
  const params = new URLSearchParams(location.search)
  const urlLocale = params.get('locale')

  // Default is German, if a locale is passed via the URL, use that. If not
  // use the stored locale from local storage.
  let locale = Locale.De
  if (urlLocale && availableLocales.includes(urlLocale.toUpperCase() as Locale)) {
    locale = urlLocale as Locale
  } else {
    const storedLocale = storage.get('locale') as Locale
    if (storedLocale && availableLocales.includes(storedLocale.toUpperCase() as Locale)) {
      locale = storedLocale
    }
  }

  locale = locale.toUpperCase() as Locale

  const messages = { [Locale.De]: de } as LocaleMessages<MessageSchema, Locale>

  // Initialize vue-i18n with the DE locale by default.
  i18n = createI18n<[MessageSchema], Locale>({
    locale: Locale.De,
    legacy: false,
    globalInjection: true,
    missingWarn: false,
    fallbackWarn: false,
    warnHtmlMessage: false,
    fallbackLocale: Locale.De,
    messages,
  })

  // If the active locale is not yet loaded, load it.
  if (!loadedLocales.includes(locale)) {
    await loadLocale(locale)
  }

  return i18n
}

function setLocale (locale: Locale) {
  //@ts-ignore
  i18n.global.locale.value = locale
  storage.set('locale', locale)
}

export async function loadLocale (locale: Locale) {
  // No change.
  if (i18n.global.locale === locale) {
    return
  }

  if (loadedLocales.includes(locale)) {
    setLocale(locale)
    return
  }

  const messages = await import(`@/locales/${locale.toLowerCase()}.json`)
  i18n.global.setLocaleMessage(locale, messages)

  setLocale(locale)
}