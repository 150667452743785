<script setup lang="ts">
  import { ExclamationIcon } from '@heroicons/vue/outline'
  import { SwitchHorizontalIcon } from '@heroicons/vue/solid'
  import { computed } from 'vue'
  import { useStore } from '@/store'
  import { useI18n } from 'vue-i18n'

  const store = useStore()
  const i18n = useI18n()

  const syncJobCount = computed(() => store.state.app.getsHTTPSyncJobs?.length ?? 0)
  const needsCommit = computed(() => store.getters['app/commitPending'])
  const syncTooltip = computed(() => needsCommit.value ? i18n.t('changelog.states.needs_commit') : i18n.t('changelog.title'))
  const systems = computed(() => store.getters['app/availableSystems'])
</script>

<template>
  <RouterLink
    v-if="systems.length > 0"
    :to="{ name: 'sync' }"
    :class="{'pointer-events-none': syncJobCount > 0}"
    class="relative leading-none bg-csBlue-600 flex-shrink-0 rounded-full p-1 text-csBlue-200 hover:text-white focus:outline-none"
  >
    <transition name="fade" mode="out-in">
      <div v-if="syncJobCount > 0">
        <svg
          class="h-6 w-6 animate-spin"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M20 4v5h-.582M4.062 11a-8.001 8.001 0 0115.356-2m0 0H15M4 20v-5h.581m0 0a-8.003 8.003 0 0015.357-2M4.581 15H9"
          />
        </svg>
        <div
          v-if="syncJobCount > 3"
          class="absolute bottom-0 right-0 -mb-1 -mr-1 text-2xs rounded-full bg-white w-5 h-5 font-bold flex items-center justify-center"
        >
          {{ syncJobCount }}
        </div>
      </div>
      <div v-else v-tooltip="syncTooltip" class="relative">
        <SwitchHorizontalIcon class="h-6 w-6" />
        <div v-if="needsCommit" class="flex h-2 w-2 absolute bottom-0 right-0 -mb-1 -mr-1">
          <span
            class="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-400 opacity-75"
          />
          <span class="relative inline-flex rounded-full h-2 w-2 bg-yellow-500" />
        </div>
      </div>
    </transition>
  </RouterLink>
  <RouterLink
    v-else
    v-tooltip="$t('systemparam.states.missing')"
    :to="{ name: 'systemParamList' }"
    class="text-yellow-500"
  >
    <ExclamationIcon class="h-6 w-6" />
  </RouterLink>
</template>

<style scoped lang="stylus">

</style>