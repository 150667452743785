import '@/assets/tailwind.styl'
import 'floating-vue/dist/style.css'
import { setupApollo, setupI18N, setupSentry } from '@/bootstrap'
import { Config, config } from '@/bootstrap/config'
import { canDirective, displayTextDirective, focusDirective, readonlyDirective } from '@/bootstrap/directives'
import { setupRouter } from '@/bootstrap/router'
import storage from '@/bootstrap/storage'
import ActionMenu from '@/common/components/actionmenu/ActionMenu.vue'
import ActionMenuItem from '@/common/components/actionmenu/ActionMenuItem.vue'
import Box from '@/common/components/Box.vue'
import ActionButton from '@/common/components/button/ActionButton.vue'
import Callout from '@/common/components/callout/Callout.vue'
import DataTableCol from '@/common/components/datatable/DataTableCol.vue'
import FormCheckbox from '@/common/components/form/FormCheckbox.vue'
import FormColorPicker from '@/common/components/form/FormColorPicker.vue'
import FormDropdown from '@/common/components/form/FormDropdown.vue'
import FormInput from '@/common/components/form/FormInput.vue'
import FormTextarea from '@/common/components/form/FormTextarea.vue'
import Loader from '@/common/components/loader/Loader.vue'
import ConfirmPopup from '@/common/components/popup/ConfirmPopup.vue'
import RecordSelector from '@/common/components/recordselector/RecordSelector.vue'
import IconCopy from '@/common/components/svg/IconCopy.vue'
import IconCross from '@/common/components/svg/IconCross.vue'
import IconRefresh from '@/common/components/svg/IconRefresh.vue'
import IconSort from '@/common/components/svg/IconSort.vue'
import IconTrash from '@/common/components/svg/IconTrash.vue'
import { Storage } from '@/common/services/storage/LocalStorage'
import { DefaultApolloClient, provideApolloClient } from '@vue/apollo-composable'
import FloatingVue from 'floating-vue'
import { createApp } from 'vue'
import App from './App.vue'
import store, { key } from './store'
import VWave from 'v-wave'

(async function boot () {
  const app = createApp(App)

  setupSentry(app)

  const i18n = await setupI18N()
  const router = setupRouter(store, i18n)
  const apolloClient = setupApollo(store, router)

  FloatingVue.options.distance = 10

  provideApolloClient(apolloClient)

  app
    .use(store, key)
    .use(router)
    .use(i18n)
    .use(FloatingVue, {
      arrowPadding: 8,
      themes: {
        'text-left': { $extend: 'tooltip' }
      }
    })
    .use(VWave, {})

  app
    .provide(Config, config)
    .provide(Storage, storage)
    .provide(DefaultApolloClient, apolloClient)

  app
    .component('FormInput', FormInput)
    .component('FormTextarea', FormTextarea)
    .component('FormDropdown', FormDropdown)
    .component('FormCheckbox', FormCheckbox)
    .component('FormColorPicker', FormColorPicker)
    .component('ActionButton', ActionButton)
    .component('ActionMenu', ActionMenu)
    .component('ActionMenuItem', ActionMenuItem)
    .component('Loader', Loader)
    .component('IconTrash', IconTrash)
    .component('IconCross', IconCross)
    .component('IconCopy', IconCopy)
    .component('IconSort', IconSort)
    .component('IconRefresh', IconRefresh)
    .component('Box', Box)
    .component('DataTableCol', DataTableCol)
    .component('ConfirmPopup', ConfirmPopup)
    .component('Callout', Callout)
    .component('RecordSelector', RecordSelector)

  app
    .directive('can', {
      beforeMount: canDirective(store),
      updated: canDirective(store)
    })
    .directive('licensed', {
      beforeMount: canDirective(store),
      updated: canDirective(store)
    })
    .directive('focus', {
      mounted: focusDirective()
    })
    .directive('display-text', displayTextDirective())
    .directive('readonly', readonlyDirective())

  app.mount('#app')

  document.documentElement.classList.remove('booting')
})()
