<script setup lang="ts">
  import { defaultFormInputProps, useFormProps } from '@/common/components/form/Forms.api'
  import { computed, onMounted, ref } from 'vue'
  import HelpTooltip from '@/common/components/HelpTooltip.vue'

  const emit = defineEmits(['update:modelValue', 'keypress'])
  const props = defineProps({
    ...defaultFormInputProps,
    type: {
      type: String,
      default: 'text',
    },
    inputClass: {
      type: String,
      default: 'text-base'
    },
    showLengthCounter: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: Number,
      default: null,
    },
    max: {
      type: [String, Number],
      default: undefined
    },
    min: {
      type: [String, Number],
      default: undefined
    },
  })
  const { realId, uniqueId, realComment, mergedContainerClasses, error } = useFormProps(props as any)

  const hasIcon = computed(() => {
    // TODO: Check if the icon slot is actually in use. This code broke with the Vue 3 update.
    return false
  })

  const remainingLength = computed(() => {
    if (!props.maxlength || !props.showLengthCounter || typeof props.modelValue !== 'string') {
      return undefined
    }
    return props.maxlength - (props.modelValue ? props.modelValue.length : 0)
  })

  const mergedInputClasses = computed(() => {
    return [
      { 'loading-shimmer': props.loading },
      { 'pr-10': hasIcon.value },
      { 'border-red-400 text-red-900 placeholder-red-300 focus:border-red-400 focus:ring-red': error.value },
      props.inputClass
    ]
  })

  const inputField = ref<HTMLInputElement>()
  onMounted(() => {
    if (props.autofocus && inputField.value) {
      inputField.value.focus()
    }
  })

  function handleInput (event: Event) {
    emit('update:modelValue', (event.target as HTMLInputElement)?.value)
  }
</script>

<template>
  <div class="cs-form-input" :class="mergedContainerClasses">
    <div class="mb-1">
      <label
        v-if="label"
        class="flex justify-between items-center text-sm leading-5 text-gray-700"
        :for="uniqueId"
      >
        <span class="truncate">{{ $t(label) }}</span>
        <slot name="label" />
      </label>
      <slot name="above-input" />
    </div>
    <div class="input-group relative rounded-md shadow-sm flex">
      <input
        v-bind="$attrs"
        :id="uniqueId"
        ref="inputField"
        class="form-input flex-grow block w-full border-gray-300 rounded-md focus:border-csBlue-300 focus:ring focus:ring-csBlue-300 focus:ring-opacity-50 sm:text-sm sm:leading-5"
        :class="mergedInputClasses"
        :disabled="disabled"
        :readonly="readonly"
        :type="type"
        :value="modelValue"
        :maxlength="maxlength"
        :data-testid="id ? id : undefined"
        :max="max"
        :min="min"
        autocomplete="off"
        @input="handleInput"
        @keypress="$emit('keypress', $event)"
      >
      <div class="absolute inset-y-0 right-0 flex items-center">
        <div
          v-if="remainingLength !== undefined"
          class="remaining-length-counter opacity-0 invisible text-sm font-semibold text-gray-400 h-full flex items-center -mt-px"
          :class="{'pr-4': !help}"
        >
          {{ remainingLength }}
        </div>
        <slot name="icon">
          <HelpTooltip v-if="help" :text="help" class="mr-2 mb-1 text-gray-500" />
        </slot>
      </div>
    </div>
    <p v-if="realComment" class="input-comment leading-tight text-gray-500 text-xs mt-2">
      {{ $t(realComment, { field: realId }) }}
    </p>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    inheritAttrs: false,
  })
</script>

<style lang="stylus" scoped>
  &.disabled
    .input-group .form-input
      @apply bg-gray-100 cursor-not-allowed
</style>

<style lang="stylus">
  .cs-form-input + .cs-form-input:not(.cs-form-input--checkbox)
    @apply mt-5

  .cs-form-input
    transition opacity .2s ease-out

    &.loading
      opacity .8

  .danger
    .input-group
      @apply border-red-500

    .input-comment
      @apply text-red-500

  .input-group:focus-within .remaining-length-counter
    @apply visible opacity-100
</style>
