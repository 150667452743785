<script setup lang="ts">
  import FormSwitch from '@/common/components/form/FormSwitch.vue'
  import { DisplayProfileSettings, SystemParam } from '@/common/graphql/types'
  import { computed, PropType, ref } from 'vue'
  import DisplayProfileSystemsSchedule from '@/modules/display/components/DisplayProfileSystemsSchedule.vue'
  import TabWidget from '@/common/components/TabWidget.vue'

  const emit = defineEmits(['update:modelValue', 'changeState'])
  const props = defineProps({
    systems: {
      type: Array as PropType<ReadonlyArray<SystemParam>>,
      default: () => []
    },
    modelValue: {
      type: Array as PropType<ReadonlyArray<DisplayProfileSettings>>,
      default: () => ([])
    }
  })

  type DisplayProfileSettingsWithConfig = DisplayProfileSettings & { enabled?: boolean, system_id?: number }

  const preselectedSystem = props.modelValue?.[0]?.system_id

  const activeSystem = ref(props.systems.find((system) => Number(system.id) === Number(preselectedSystem)) || props.systems[0])
  const activeConfig = computed(() => props.modelValue.find((setting) => setting.system_id === Number(activeSystem.value.id)) as DisplayProfileSettingsWithConfig)

  function systemIsEnabled (checkSystem: SystemParam) {
    const system = props.modelValue.find((setting) => setting.system_id === Number(checkSystem.id)) as DisplayProfileSettingsWithConfig
    return !!system?.system_id && system?.enabled !== false
  }

  function updateValue (value: any) {
    emit('update:modelValue', {
      system_id: Number(activeSystem.value.id),
      ...value
    })
  }
</script>

<template>
  <div>
    <TabWidget v-model="activeSystem" :tabs="systems">
      <template #default="{ active }">
        <div
          v-for="system in systems"
          v-show="system.id === active.id"
          :key="system.id"
        >
          <div>
            <FormSwitch
              name="show_calls_from_system"
              :modelValue="systemIsEnabled(system)"
              :label="$t('display.profile.fields.show_calls_from_system', [system.name])"
              :comment="$t('display.profile.fields.show_calls_from_system_comment')"
              @update:modelValue="updateValue({ ...activeConfig, enabled: $event })"
            />
          </div>
          <DisplayProfileSystemsSchedule
            v-if="systemIsEnabled(system)"
            class="mt-4"
            :system="system"
            :config="activeConfig"
            @update:modelValue="updateValue"
          />
        </div>
      </template>
    </TabWidget>
  </div>
</template>