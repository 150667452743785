import { config } from '@/bootstrap/config'
import { differenceInSeconds, parseISO, useNow } from '@/common/services/time'
import { Action } from 'vuex'
import { State } from '@/store/index'
import { AppDataQuery, Features, GlobalAppSettings, GlobalSettings, Hub, SystemParam } from '@/common/graphql/types'
import { RouteLocationNormalized } from 'vue-router'
import { getInMemoryCache, getWsClient } from '@/bootstrap/apollo'

export const SystemContextKey = 'cs.system_context'

interface RecordSelectorState {
  active: string;
}

export interface GetsHTTPSyncJob {
  id: string;
  action: string;
  data: Record<string, unknown>
  entity: Record<string, unknown>
  auto_commit: boolean
}

type RelevantGlobalSettings = Pick<
  GlobalSettings,
    'ignore_linkable_node_pool_for_super_users'
  | 'allow_empty_call_source_names'
  | 'system_tree_text_long_required'
  | 'battery_threshold_low'
  | 'battery_threshold_critical'
>

export interface AppState {
  started: Date
  getsConfigImportProgress: number
  settings: RelevantGlobalSettings
  app_settings: {
    subscriptions_enabled: boolean
  }
  onlineBackupToken: string
  serverStart: Date;
  isFullscreen: boolean;
  offlineMode: boolean;
  appGatewayEnabled: boolean;
  appGatewaySystemId: number;
  features: Features;
  getsHTTPSyncJobs: GetsHTTPSyncJob[],
  isBackendConnected: boolean;
  availableUpdate: string;
  recordSelector: RecordSelectorState;
  lastGetsCommit: Date
  lastGetsChange: Date
  lastDisplayConfigUpdate: Date
  committingGetsConfig: Date | null
  setupTime: string | null | undefined
  configuratorIP: string
  configuratorVersion: number
  videoStreams: string[]
  outdatedHubs: Pick<Hub, 'id' | 'name' | 'last_sync'>[]
  onlineBackupResult: string
  currentRoute: RouteLocationNormalized | null
  systems: SystemParam[]
  // The currently active system.
  systemContext: string | null
  systemContextLoading: boolean
}

let urlSystemOverride = null
const params = new URLSearchParams(window.location.search)
if (params.has('system')) {
  urlSystemOverride = params.get('system')
}

const state: AppState = {
  started: new Date(),
  isFullscreen: false,
  offlineMode: false,
  appGatewayEnabled: false,
  appGatewaySystemId: 1,
  getsHTTPSyncJobs: [],
  lastGetsCommit: new Date(),
  lastGetsChange: new Date('1970-01-01'),
  lastDisplayConfigUpdate: new Date('1970-01-01'),
  getsConfigImportProgress: 0,
  committingGetsConfig: null,
  setupTime: undefined,
  isBackendConnected: false,
  recordSelector: { active: '' },
  serverStart: new Date(),
  availableUpdate: config.version,
  videoStreams: [],
  outdatedHubs: [],
  onlineBackupResult: '',
  onlineBackupToken: '',
  configuratorIP: '',
  configuratorVersion: 0,
  settings: {
    ignore_linkable_node_pool_for_super_users: false,
    system_tree_text_long_required: false,
    allow_empty_call_source_names: false,
    battery_threshold_low: 40,
    battery_threshold_critical: 25,
  },
  app_settings: {
    subscriptions_enabled: true,
  },
  features: {
    display_group_overrides: false,
    demo_mode: false,
  },
  currentRoute: null,
  systems: [],
  systemContext: urlSystemOverride ?? (localStorage.getItem(SystemContextKey) || '1'),
  systemContextLoading: false,
}

const getters = {
  // availableSystems returns all systems that are available to the current user.
  availableSystems: (state: AppState, getters: any, rootState: State) => {
    if (rootState.user.user.is_superuser) {
      return state.systems
    }

    const systemAccess = (rootState.user.user.system_access ?? [])
    if (systemAccess.length === 0) {
      return state.systems
    }

    return state.systems.filter(s => systemAccess.includes(Number(s.id)))
  },
  commitPending (state: AppState) {
    return state.lastGetsChange > state.lastGetsCommit
  },
  isCommittingGetsConfig (state: AppState) {
    if (!state.committingGetsConfig) {
      return false
    }
    // If the "lock" from the backend is older than 15 minutes, regard it as invalid.
    // In case a lock is this old, there might have been a problem releasing it once
    // the configurator was done committing the new config.
    const maxAgeInSeconds = 60 * 15
    return differenceInSeconds(state.committingGetsConfig, useNow()) < maxAgeInSeconds
  },
}

const actions: Record<string, Action<AppState, State>> = {
  setState ({ commit }, data: AppDataQuery) {
    commit('setOfflineMode', data.appState.offline_mode)
    commit('setAppGatewayEnabled', data.appState.app_gateway_enabled)
    commit('setLastGetsChange', data.appState.last_gets_change)
    commit('setLastGetsCommit', data.appState.last_gets_commit)
    commit('setCommittingGetsConfig', data.appState.committing_gets_config)
    commit('setConfiguratorIP', data.appState.configurator_ip)
    commit('setConfiguratorVersion', data.appState.configurator_version)
    commit('setSetupTime', data.appState.setup_time)
    commit('setSystems', data.publicSystemParams)
    commit('setFeatures', data.appState.features)
    commit('setAppGatewaySystemId', data.appState.app_gateway_system_id)
    commit('setOutdatedHubs', data.appState.outdated_hubs)

    if (data.globalAppSettings) {
      commit('setGlobalAppSettings', data.globalAppSettings)
    }
  },
  async setSystemContext ({ commit }, args: { systemId: number, fetchAppData: () => Promise<void> }) {
    commit('setSystemContext', args.systemId)
    localStorage.setItem(SystemContextKey, args.systemId.toString())

    // Reset Apollo Websocket connection and cache.
    getWsClient().restart()
    await getInMemoryCache().reset()

    // Reload the app data.
    await args.fetchAppData()
  }
}

const mutations = {
  setIsBackendConnected (state: AppState, value: boolean) {
    state.isBackendConnected = value
  },
  setIsFullscreen (state: AppState, value: boolean) {
    state.isFullscreen = value
  },
  setOfflineMode (state: AppState, value: boolean) {
    state.offlineMode = value
  },
  setAppGatewayEnabled (state: AppState, value: boolean) {
    state.appGatewayEnabled = value
  },
  setFeatures (state: AppState, value: Features) {
    state.features = value
  },
  setGlobalAppSettings (state: AppState, value: GlobalAppSettings) {
    state.app_settings.subscriptions_enabled = value.subscriptions_enabled
  },
  setAppGatewaySystemId (state: AppState, value: number) {
    state.appGatewaySystemId = value
  },
  addGetsHTTPSyncJob (state: AppState, jobs: GetsHTTPSyncJob[]) {
    state.getsHTTPSyncJobs = jobs

    const needsCommit = jobs.some(j => !j.auto_commit)
    if (needsCommit) {
      state.lastGetsChange = new Date()
    }
  },
  removeGetsHTTPSyncJob (state: AppState, jobs: GetsHTTPSyncJob[]) {
    state.getsHTTPSyncJobs = jobs
  },
  setLastGetsChange (state: AppState, value: Date | string = new Date()) {
    if (typeof value === 'string') {
      value = parseISO(value)
    }
    state.lastGetsChange = value
  },
  setLastGetsCommit (state: AppState, value: Date | string = new Date()) {
    if (typeof value === 'string') {
      value = parseISO(value)
    }
    state.lastGetsCommit = value
  },
  setCommittingGetsConfig (state: AppState, value: Date | string | null) {
    if (typeof value === 'string') {
      value = parseISO(value)
    }
    state.committingGetsConfig = value
  },
  setGetsConfigImportProgress (state: AppState, value: number) {
    state.getsConfigImportProgress = value
  },
  setSetupTime (state: AppState, value: string) {
    state.setupTime = value
  },
  setSettings (state: AppState, value: GlobalSettings) {
    state.settings = value
  },
  setConfiguratorIP (state: AppState, value: string) {
    state.configuratorIP = value
  },
  setConfiguratorVersion (state: AppState, value: number) {
    state.configuratorVersion = value
  },
  setOutdatedHubs (state: AppState, value: Pick<Hub, 'id' | 'name' | 'last_sync'>[]) {
    state.outdatedHubs = value
  },
  openRecordSelector (state: AppState, subject: string) {
    state.recordSelector.active = subject
  },
  closeRecordSelector (state: AppState) {
    state.recordSelector.active = ''
  },
  setAvailableUpdate (state: AppState, version: string) {
    state.availableUpdate = version
  },
  serverStart (state: AppState, time: Date) {
    state.serverStart = time
  },
  addVideoStream (state: AppState, url: string) {
    if (!state.videoStreams.includes(url)) {
      state.videoStreams.push(url)
    }
  },
  removeVideoStream (state: AppState, url: string) {
    state.videoStreams = state.videoStreams.filter(v => v !== url)
  },
  setOnlineBackupResult (state: AppState, result: string) {
    state.onlineBackupResult = result
  },
  setOnlineBackupToken (state: AppState, token: string) {
    state.onlineBackupToken = token
  },
  setCurrentRoute (state: AppState, route: RouteLocationNormalized) {
    state.currentRoute = route
  },
  setSystems (state: AppState, systems: SystemParam[]) {
    state.systems = systems
  },
  setSystemContext (state: AppState, systemId: string) {
    state.systemContext = systemId
  },
  displayConfigUpdated (state: AppState) {
    state.lastDisplayConfigUpdate = new Date()
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}