<template>
  <div class="clock text-gray-700 relative z-20">
    <div class="font-semibold time leading-tight">
      {{ time }}
    </div>
    <div class="date">
      {{ date }}
    </div>
  </div>
</template>

<script lang="ts">
  import { format, useTime } from '@/common/services/time'
  import { computed, defineComponent } from 'vue'

  export default defineComponent({
    name: 'Clock',
    setup () {
      const clock = useTime()
      const time = computed(() => format(clock.value.now, 'HH:mm'))
      const date = computed(() => format(clock.value.now, 'PPPP'))

      return {
        time,
        date,
      }
    }
  })
</script>

<style lang="stylus" scoped>
  .clock
    position fixed
    text-align center
    left 50%
    top 50%
    width 100%
    transform translate(-50%, -65%)

    &.small
      position absolute
      transform none
      left 0
      bottom 0
      top auto
      width auto
      .time
        font-size 5.5rem
      .date
        display none

  .time
    font-size 15rem
    font-variant-numeric tabular-nums
  .date
    font-size 4rem
    text-align center

  @media screen and (max-width: 1600px)
    .time
      font-size 12rem
    .date
      font-size 3rem

  @media screen and (max-width: 1400px)
    .time
      font-size 9rem
    .date
      font-size 2rem

  @media screen and (max-width: 800px)
    .time
      font-size 7rem
    .date
      font-size 1.5rem

  @media screen and (max-width: 600px)
    .time
      font-size 4rem
    .date
      font-size 1rem
</style>
