<script setup lang="ts">
  import IconFullscreen from '@/common/components/svg/IconFullscreen.vue'
  import { Call as GqlCall, CallTypeFlag, DisplayNote as GqlDisplayNote, DisplayProfile } from '@/common/graphql/types'
  import DisplayNotes from '@/modules/display/components/DisplayNotes.vue'
  import { LayoutDisplayConfig } from '@/modules/display/composables/Calls.api'
  import { computed, PropType, ref, toRefs, watch } from 'vue'
  import { useDocumentVisibility } from '@vueuse/core'
  import { useStore } from '@/store'
  import Clock from './Clock.vue'
  import EmptyState from './EmptyState.vue'
  import { onBeforeRouteLeave } from 'vue-router'
  import VideoStream from '@/modules/display/components/VideoStream.vue'
  import { useI18n } from 'vue-i18n'
  import { BellIcon, CogIcon } from '@heroicons/vue/outline'
  import ListLayout from '@/modules/display/components/layouts/ListLayout.vue'
  import GridLayout from '@/modules/display/components/layouts/GridLayout.vue'

  defineEmits(['setLayout', 'close', 'openSettings'])
  const props = defineProps({
    calls: {
      type: Array as PropType<GqlCall[]>,
      default: () => ([])
    },
    presences: {
      type: Array as PropType<GqlCall[]>,
      default: () => ([])
    },
    displayProfile:{
      type: Object as PropType<DisplayProfile>,
      default: null,
    },
    displayConfig: {
      type: Object as PropType<LayoutDisplayConfig>,
      required: true,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    videoStream: {
      type: Boolean,
      default: true,
    },
    layout: {
      type: String,
      default: 'grid',
    },
    displayNotes: {
      type: Array as PropType<ReadonlyArray<Partial<GqlDisplayNote>>>,
      default: () => ([])
    },
    fontSize: {
      type: String,
      default: 'normal'
    }
  })

  const store = useStore()
  const i18n = useI18n()

  const layouts: Record<string, any> = {
    'list': ListLayout,
    'grid': GridLayout
  }

  const layoutComponent = computed(() => layouts[props.layout] ?? GridLayout)
  const isEmpty = computed(() => props.calls.length === 0 && props.presences.length === 0)
  const isFullscreen = computed(() => store.state.app.isFullscreen)
  const notificationsEnabled = ref(localStorage.getItem('cs.display.notifications_enabled') === 'on')
  const refProps = toRefs(props)
  const visibility = useDocumentVisibility()
  const notificationsSupported = window.isSecureContext

  const videoStreams = computed(() => props.videoStream ? store.state.app.videoStreams : [])

  function setFullscreen (state: boolean) {
    store.commit('app/setIsFullscreen', state)
  }

  watch(() => isFullscreen.value, newValue => {
    if (newValue) {
      store.commit('notifications/notify', {
        title: i18n.t('common.notifications.fullscreen.title'),
        text: i18n.t('common.notifications.fullscreen.text'),
        type: 'info',
        duration: 8000
      })
    }
  })

  onBeforeRouteLeave(() => {
    setFullscreen(false)
  })

  watch(notificationsEnabled, (newValue) => {
    if (!('Notification' in window)) {
      return
    }

    if (newValue && Notification.permission !== 'denied') {
      Notification.requestPermission()
    }

    localStorage.setItem('cs.display.notifications_enabled', newValue ? 'on' : 'off')
  })

  watch(refProps.calls, (newValue, oldValue) => {
    if (!('Notification' in window)) {
      return
    }

    if (Notification.permission !== 'denied' && notificationsEnabled.value && visibility.value === 'hidden') {
      const oldIDs = oldValue.map(call => call.id)
      const added = newValue.filter(call => !oldIDs.includes(call.id))

      if (added.length > 0) {
        added.forEach((call: GqlCall) => {
          if (call.call_type?.flag === CallTypeFlag.Presence) {
            return
          }

          const notification = new Notification(call.device_text_long ? call.device_text_long : call.device_text_short, {
            body: call.call_type_text + (call.position ? ` (${call.position})` : '')
          })

          notification.addEventListener('click', function () {
            window.focus()
          })
        })
      }
    }
  })

  const showNotes = computed(() => props.layout === 'grid' && props.displayNotes?.length > 0)
</script>

<template>
  <div>
    <Box
      v-if="!fullscreen"
      class="mt-0 py-0 shadow mb-4"
    >
      <div class="flex justify-between items-center p-2">
        <div class="flex justify-start items-center">
          <ActionButton
            v-if="store.state.user.user.id"
            v-tooltip="$t('display.settings')"
            displayMode="icon"
            class="border-gray-400 mr-2 h-10 w-10 !p-0"
            @click="$emit('openSettings')"
          >
            <template #icon>
              <CogIcon class="w-[1.4rem] text-gray-600 text-current" />
            </template>
          </ActionButton>

          <div v-if="displayProfile" class="inline-block px-3 py-1 text-xs bg-gray-100 rounded font-semibold ml-2">
            {{ displayProfile.name }}
          </div>
          <!--
          ==
           Disabled, until some wants it back.
          ==

          <ActionButton
            v-tooltip="$t('call.display.view_grid')"
            displayMode="icon"
            class="border-gray-400 mr-2 h-10 w-10 !p-0"
            :class="{'bg-gray-100 shadow-inner': layoutComponent === 'grid-layout'}"
            @click="$emit('setLayout', 'grid')"
          >
            <template #icon>
              <IconGrid class="w-3.5 text-gray-600" />
            </template>
          </ActionButton>

          <ActionButton
            v-tooltip="$t('call.display.view_list')"
            displayMode="icon"
            class="border-gray-400 h-10 w-10 !p-0"
            :class="{'bg-gray-100 shadow-inner': layoutComponent === 'list-layout'}"
            @click="$emit('setLayout', 'list')"
          >
            <template #icon>
              <IconList class="w-4 text-gray-600" />
            </template>
          </ActionButton>
          -->
        </div>
        <div class="flex justify-end items-center">
          <ActionButton
            v-if="notificationsSupported"
            v-tooltip="$t('call.display.notifications')"
            displayMode="icon"
            class="border-gray-400 mr-2 h-10 w-10 !p-0"
            :class="{'!bg-csBlue-100 border-csBlue-800 text-white shadow-inner hover:text-white': notificationsEnabled}"
            @click="notificationsEnabled = !notificationsEnabled"
          >
            <template #icon>
              <BellIcon class="w-5 text-gray-600 text-current" />
            </template>
          </ActionButton>
          <ActionButton
            v-tooltip="$t('call.display.fullscreen')"
            displayMode="icon"
            class="border-gray-400 h-10 w-10 !p-0"
            @click="setFullscreen(!fullscreen)"
          >
            <template #icon>
              <IconFullscreen class="w-4 text-gray-600" />
            </template>
          </ActionButton>
        </div>
      </div>
    </Box>
    <div class="visuals-display flex" :class="['size-' + fontSize, {'p-10 min-h-screen': isFullscreen}]">
      <div :class="showNotes ? 'w-3/4' : 'w-full'" class="relative">
        <component
          :is="layoutComponent"
          v-show="!isEmpty"
          :calls="calls"
          :presences="presences"
          :displayConfig="displayConfig"
          :displayProfile="displayProfile"
          :hasNotes="showNotes"
          :fontSize="fontSize"
          @close="$emit('close', $event)"
        />
        <Clock v-if="fullscreen && !isEmpty" class="small" />
        <div v-if="isEmpty" @click="setFullscreen(false)">
          <Clock v-if="fullscreen" :class="displayNotes.length ? 'small' : ''" />
          <EmptyState v-else />
        </div>
      </div>
      <DisplayNotes v-if="showNotes" :displayNotes="displayNotes" />
    </div>
  </div>

  <teleport to="body">
    <transition-group
      enterActiveClass="transition ease-out duration-300"
      enterFromClass="opacity-0 scale-75"
      enterToClass="opacity-100 scale-100"
      leaveActiveClass="transition ease-in duration-200"
      leaveFromClass="opacity-100 scale-100"
      leaveToClass="opacity-0 scale-75"
    >
      <VideoStream
        v-for="(stream, index) in videoStreams"
        :key="stream"
        :multiple="videoStreams.length > 1"
        :url="stream"
        :index="index"
      />
    </transition-group>
  </teleport>
</template>

<style lang="stylus">
  .visuals-display
    container display / inline-size

    &.size-normal

      .title
        font-size 1.5rem

      .subtitle
        font-size 1.2rem

      .text-base
        font-size .9375rem

      .text-xl
        font-size 1.25rem

      .text-sm
        font-size .875rem

      .text-2xl
        font-size 1.15rem

      .clock.small .time
        font-size 5.5rem

      @container display (width < 1000px)
        .calls
          grid-template-columns repeat(2, minmax(calc(100% / 3 - 10px), 1fr))

      @container display (width < 800px)
        .calls
          grid-template-columns 1fr

    &.size-larger
      .title
        font-size 2rem

      .subtitle
        font-size 1.6rem

      .text-base
        font-size 1.2rem

      .text-xl
        font-size 1.6rem

      .text-sm
        font-size 1.2rem

      .text-2xl
        font-size 1.6rem

      .clock.small .time
        font-size 7rem

      .presences
        flex 0 0 360px
        grid-template-columns repeat(auto-fill, minmax(250px, 1fr))

      @container display (width < 1200px)
        .calls
          grid-template-columns repeat(2, minmax(calc(100% / 3 - 10px), 1fr))

      @container display (width < 950px)
        .calls
          grid-template-columns 1fr

    &.size-largest
      .title
        font-size 2.5rem

      .subtitle
        font-size 2rem

      .text-base
        font-size 1.6rem

      .text-xl
        font-size 2rem

      .text-sm
        font-size 1.6rem

      .text-2xl
        font-size 2rem

      .clock.small .time
        font-size 9rem

      .call
        padding 1.5rem

      @container display (width < 1600px)
        .calls
          grid-template-columns repeat(2, minmax(calc(100% / 3 - 10px), 1fr))

      @container display (width < 1150px)
        .calls
          grid-template-columns 1fr

      .presences
        flex 0 0 425px
        grid-template-columns repeat(auto-fill, minmax(425px, 1fr))
</style>