<script setup lang="ts">
  import RecordSelectorOptions from '@/common/components/recordselector/RecordSelectorOptions.vue'
  import { computed, PropType } from 'vue'
  import { FormErrorsCollection, getError } from '@/common/components/form/Forms.api'

  const emit = defineEmits(['open', 'close'])
  const props = defineProps({
    compactLayout: {
      type: Boolean,
      default: false
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'gray-200'
    },
    optionsTitle: {
      type: String,
      required: true
    },
    errors: {
      type: Object as PropType<FormErrorsCollection>,
      default: () => ({})
    },
    iconClasses: {
      type: [Object, String],
      default: ''
    },
    invisible: {
      type: Boolean,
      default: false,
    },
    id: { type: [String, Number], default: undefined },
    loading: {
      type: Boolean,
      default: false,
    },
    updating: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  })

  const error = computed(() => {
    if (props.errors && props.id) {
      return getError(props.errors, props.id as string)
    }
    return ''
  })

  function onClick (e: MouseEvent) {
    if (props.disabled) {
      return
    }
    // Clicks on the action slot should not trigger a open event.
    if ((e.target as HTMLElement).closest('.actions')) {
      return
    }
    emit('open')
  }
</script>

<template>
  <div>
    <div
      class="cs-form-input record-selector z-10 hover:bg-gray-50 hover:shadow cursor-pointer bg-white border border-gray-300 rounded shadow-sm flex leading-none items-center select-none flex-1"
      :class="{
        'danger': error,
        'disabled': disabled,
        'record-selector--compact': compactLayout,
        'record-selector--invisible': invisible,
        'loading-shimmer': loading,
      }"
      @click="onClick"
    >
      <template v-if="loading">
        <template v-if="!compactLayout">
          <div class="icon p-4">
            <span class="rounded-full w-8 h-8 flex justify-center items-center loading-shimmer" />
          </div>
          <div class="flex-1">
            <div class="loading-block w-48" />
            <div class="loading-block mt-2 w-2" />
          </div>
        </template>
      </template>
      <template v-else>
        <div class="icon">
          <span class="rounded-full flex justify-center items-center" :class="iconClasses">
            <slot name="icon" />
          </span>
        </div>
        <div class="flex-1 overflow-hidden mr-2" :class="compactLayout ? '' : 'py-4'">
          <div class="text-base truncate" :class="compactLayout ? '' : 'font-bold'">
            <slot name="title" />
          </div>
          <div v-if="!compactLayout" class="text-gray-400 text-sm mt-2 truncate">
            <slot name="subtitle" />
          </div>
        </div>
        <div class="flex-0 mr-4 actions">
          <div class="flex">
            <slot name="actions" />
          </div>
        </div>
        <teleport to="body">
          <transition name="slide">
            <RecordSelectorOptions v-if="isOpen" :title="optionsTitle" @close="$emit('close')">
              <template #actions>
                <slot name="header" />
              </template>
              <slot />
              <div
                class="transition duration-200 relative h-full"
                :class="{'opacity-50 pointer-events-none user-select-none': updating}"
              >
                <slot name="updateable" />
              </div>
            </RecordSelectorOptions>
          </transition>
        </teleport>
      </template>
    </div>
    <div v-if="error" class="mt-2 text-red-500 text-xs">
      {{ error }}
    </div>
  </div>
</template>

<style lang="stylus" scoped>
  .record-selector
    min-height 70px
    position relative

    &.danger
      @apply border-red-500

    &.disabled
      @apply cursor-not-allowed bg-gray-100

    &--invisible
      display none

    &--compact
      @apply shadow-sm border-gray-300 rounded-md
      min-height 38px

      &:focus
        @apply ring ring-csBlue-200 border-csBlue-300

      .icon
        padding 8px
        width 32px
        height 32px

        > span
          @apply h-4 w-4

  .icon
    @apply p-4
    width 64px
    height 64px

    > span
      @apply h-8 w-8
</style>
