<script setup lang="ts">
  import { AuthUserQuery, UserSetPasswordMutation, UserSetPasswordMutationVariables } from '@/common/graphql/types'
  import { useStore } from '@/store'
  import { useMutation, useQuery } from '@vue/apollo-composable'
  import { reactive } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'
  import QueryAuthUser from './graphql/QueryAuthUser.gql'
  import MutationSetPassword from './graphql/MutationSetPassword.gql'
  import { transformErrors } from '@/common/components/form/Forms.api'
  import Callout from '@/common/components/callout/Callout.vue'

  const i18n = useI18n()
  const store = useStore()
  const router = useRouter()

  const state = reactive({
    password: '',
    password_repeat: '',
    errors: {},
    loading: false,
  })

  const { onResult } = useQuery<AuthUserQuery>(QueryAuthUser, {}, { fetchPolicy: 'no-cache' })
  onResult(result => {
    if (!result || result.loading) {
      return
    }

    if (!result?.data?.authUser) {
      return router.push({ name: 'login' })
    }
  })

  const { mutate } = useMutation<UserSetPasswordMutation, UserSetPasswordMutationVariables>(MutationSetPassword, {
    fetchPolicy: 'no-cache',
  })

  async function submit () {
    state.loading = true
    try {
      await mutate({
        password: state.password,
        password_repeat: state.password_repeat,
      })

      await router.push({ name: 'homepage' })

      store.commit('notifications/notify', {
        type: 'success',
        title: i18n.t('user.notifications.set_password.title'),
        text: i18n.t('user.notifications.set_password.text'),
      })
    } catch (err: any) {
      if (err.message?.includes('validation failed') && err.hasOwnProperty('graphQLErrors')) {
        state.errors = transformErrors(err.graphQLErrors)
      } else {
        store.commit('notifications/notify', {
          type: 'error',
          title: i18n.t('common.notifications.update.error.title'),
          text: i18n.t('common.notifications.update.error.text'),
        })
      }
    } finally {
      state.loading = false
    }
  }
</script>

<template>
  <div>
    <form autocomplete="off" class="home">
      <div class="flex flex-col justify-center items-center">
        <Callout text="user.callouts.password_is_temporary.title" class="border border-csBlue-200 shadow">
          {{ $t('user.callouts.password_is_temporary.text') }}
        </Callout>
      </div>
      <div class="mt-4 shadow-lg rounded max-w-lg p-6 bg-white mx-auto">
        <FormInput
          id="password"
          v-model="state.password"
          :autofocus="true"
          :errors="state.errors"
          label="user.fields.new_password"
          name="password"
          type="password"
          autocomplete="off"
        />
        <FormInput
          id="password_repeat"
          v-model="state.password_repeat"
          :errors="state.errors"
          label="user.fields.password_repeat"
          name="password_repeat"
          type="password"
          autocomplete="off"
          @keydown.enter="submit"
        />
        <ActionButton
          id="submit"
          type="primary"
          :loading="state.loading"
          class="text-base py-3 w-full mt-6 uppercase"
          @click="submit"
        >
          {{ $t('user.actions.set_password') }}
        </ActionButton>
      </div>
    </form>
  </div>
</template>

<style lang="stylus" scoped>
  .home
    max-width calc(100% - 40px)
    width 400px
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -70%)

    @media screen and (max-height: 800px)
      transform translate(-50%, -50%)
</style>
